<template>
  <p>404</p>
</template>

<script>
export default {
  name: 'Nofined',
  props: {
    msg: String
  }
};
</script>

<style scoped>

</style>
